




































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { deleteFormData, getFormData, getFormDataByWidget, saveFormData } from '@/api/form-design/FormApi';

@Component({
  name: 'FormDataManager',
  components: { FormViewer: () => import('@/components/form-design/viewer/form-viewer.vue') }
})
export default class FormDataManager extends Vue {
  @Prop({
    default() {
      return {};
    }
  })
  form!: any;

  @Prop({
    default() {
      return '';
    }
  })
  widgetId!: string;

  pageIndex: number = 1; //当前页面
  pageSize: number = 10; // 当前页数
  total: number = 0; // 总条数
  searchForm: any = {};

  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  tableConfig: any = {
    columnKeys: [],
    htmlKeys: [],
    visibleKey: '', // 结果显示项
    valueKey: [] // 主键
  }; // 表单列配置
  tableColumns: any[] = []; // 表单的列
  tableData: any = []; // 表单分页数据
  formDialogVisible: boolean = false;
  created() {
    this.getData();
  }

  //获取表格数据源
  getData() {
    if (this.form.code) {
      if (this.widgetId) {
        let params: any = {
          formId: this.form.belongFormId,
          page: {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          },
          formModelId: this.widgetId
        };
        params = Object.assign(this.searchForm, params);
        // 获取该组件配置的对应范围的表单数据
        getFormDataByWidget(this.searchForm).then((res: any) => {
          if ((res.code == 1)) {
            if (res.data && res.data.list && res.data.list.length) {
              res.data.list.sort((a: any, b: any) => {
                return a.columns[2].value - b.columns[2].value
              })
              this.buildTableColumn(res.data.list);
              this.buildTableConfig(res.data.list);
              this.buildTableData(res.data);
            } else {
              this.tableColumns = [];
              this.tableConfig = {
                columnKeys: [],
                htmlKeys: [],
                visibleKey: '', // 结果显示项
                valueKey: [] // 主键
              }; //
              this.tableData = [];
              this.pageIndex = 1;
              this.pageSize = 10;
              this.total = 0;
            }
          }
        });
      } else {
        let params: any = {
          formCode: this.form.code,
          page: {
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          }
        };
        params = Object.assign(this.searchForm, params);
        // 获取所有表单数据
        getFormData(this.searchForm).then((res: any) => {
          if ((res.code = 1)) {
            if (res.data && res.data.list && res.data.list.length) {
              this.buildTableColumn(res.data.list);
              this.buildTableConfig(res.data.list);
              this.buildTableData(res.data);
            } else {
              this.tableColumns = [];
              this.tableConfig = {
                columnKeys: [],
                htmlKeys: [],
                visibleKey: '', // 结果显示项
                valueKey: [] // 主键
              }; //
              this.tableData = [];
              this.pageIndex = 1;
              this.pageSize = 10;
              this.total = 0;
            }
          }
        });
      }
    }
  }

  /**
   * 构建表格表头
   * @param {void} null
   * @returns void
   */
  buildTableColumn(tableData) {
    if (tableData && tableData.length) {
      let columns = tableData[0].columns;
      this.tableColumns = columns.filter(item => {
        return item.display == true;
      });
      this.tableColumns.sort((x, y) => {
        return x.index - y.index;
      });
    }
  }

  /**
   * 构建表格配置
   * @param {number} param
   * @returns {number}
   */
  buildTableConfig(tableData) {
    if (tableData && tableData.length) {
      let columns = tableData[0].columns;
      columns.forEach(item => {
        if (item.display) {
          this.tableConfig.columnKeys.push(item.key);
        }
        if (item.html) {
          this.tableConfig.htmlKeys.push(item.key);
        }
        if (item.visible) {
          this.tableConfig.visibleKey = item.key;
        }
        if (item.val) {
          this.tableConfig.valueKey.push(item.key);
        }
      });
    }
  }

  /**
   * 构建表格数据
   * @param {number} param
   * @returns {number}
   */
  buildTableData(pageInfo) {
    let result: any[] = [];
    let tableData = pageInfo.list;
    tableData.forEach(row => {
      let item = {};
      row.columns.forEach(column => {
        item[column.key] = column.value;
      });
      result.push(item);
    });
    this.tableData = result;
    this.pageIndex = pageInfo.pageNum;
    this.pageSize = pageInfo.pageSize;
    this.total = pageInfo.total;
    return result;
  }

  // 搜索
  handleSearch() {
    this.getData();
  }

  //清空搜索
  resetSearch() {
    this.searchForm = {};
  }

  // 当前页码修改时
  handleCurrentChange(pageIndex) {
    this.pageIndex = pageIndex;
    this.getData();
  }
  // 当前页数修改时
  handleSizeChange(pageSize) {
    this.pageSize = pageSize;
    this.getData();
  }

  /**
   * 新增数据
   */
  handleAdd() {
    this.formId = this.form.id;
    this.formDataId = null;
    this.formDialogVisible = true;
  }

  /**
   * description
   */
  handleEidt(row) {
    this.formId = this.form.id;
    this.formDataId = row.formDataId;
    this.formDialogVisible = true;
  }

  /**
   * description  提交
   * @param {}
   * @returns {}
   */
  handleSave() {
    (this.$refs.formViewerRef as any).getFormDataForSave().then(result => {
      saveFormData(result).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.formDialogVisible = false;
          this.getData();
        }
      });
    });
  }

  /**
   * 删除表单数据
   */
  handleDelete(row) {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        let params = {};
        this.tableConfig.valueKey.forEach(key => {
          params[key] = row[key];
        });
        deleteFormData(params).then((res: Record<string, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.getData();
          }
        });
      })
      .catch(() => { });
  }

  @Watch('form')
  formCodeWatcher(val) {
    this.getData();
  }
}
